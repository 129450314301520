import React from "react"
import { Section, breakpointsMax, SectionTitle, Hero } from "@dodobardo/dodolib"
import styled, { useTheme } from "styled-components"
import { useTranslation } from "react-i18next"
import { Hr, Image } from "../components"
import {
    company,
    company_external,
    company_internal,
    cnc,
    internal_machine,
} from "../res/imgs"

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 50px;
    @media ${breakpointsMax.tablet} {
        padding: 40px;
    }

    a {
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.grey.primary};
    }
`

const Home = () => {
    const { t } = useTranslation()
    const theme = useTheme()

    return (
        <>
            <Hero
                title={t("heroes.titles.about")}
                subtitle={t("heroes.subtitles.about")}
                bgImage={cnc}
            />
            <Container>
                <SectionTitle>{t("about.history")}</SectionTitle>
            </Container>
            <Section
                bg={theme.colors.white}
                textColor={theme.colors.grey.primary}
                body={t("sections.about.first")}
            >
                <Image src={company} />
            </Section>
            <Hr />
            <Container>
                <SectionTitle>{t("about.company")}</SectionTitle>
            </Container>
            <Section
                bg={theme.colors.white}
                textColor={theme.colors.grey.primary}
                body={t("sections.about.second")}
                left
            >
                <Image left src={company_external} />
            </Section>
            <Section
                bg={theme.colors.white}
                textColor={theme.colors.grey.primary}
                body={t("sections.about.third")}
            >
                <Image src={company_internal} />
            </Section>
            <Section
                bg={theme.colors.white}
                textColor={theme.colors.grey.primary}
                body={t("sections.about.fourth")}
                left
            >
                <Image src={internal_machine} />
            </Section>
        </>
    )
}

export default Home
