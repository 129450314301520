import React from "react"
import { Footer as Wrapper, ContactInfo } from "@dodobardo/dodolib"
import { Location } from "@styled-icons/evil/Location"
import { Email } from "@styled-icons/material-outlined/Email"
import { PhoneOutline } from "@styled-icons/evaicons-outline/PhoneOutline"
import { Fax } from "@styled-icons/fa-solid/Fax"
import { Calendar } from "@styled-icons/ionicons-outline/Calendar"
import { useTranslation } from "react-i18next"
import LanguageSelector from "./LanguageSelector"

const Footer = () => {
    const { t } = useTranslation()

    return (
        <Wrapper lg primary t={t}>
            <ContactInfo
                link={`http://maps.google.com/?q=${t("company.address")}`}
                Img={Location}
                primary
                text={t("company.address")}
            />
            <ContactInfo
                link={`mailto:${t("company.email")}`}
                Img={Email}
                primary
                text={t("company.email")}
            />
            <div>
                <ContactInfo
                    link={`tel:${t("company.phone_number")}`}
                    primary
                    Img={PhoneOutline}
                    text={t("company.phone_number")}
                />
            </div>
            <ContactInfo
                link={`tel:${t("company.phone_number")}`}
                primary
                Img={Fax}
                text={t("company.fax")}
            />
            <LanguageSelector>ITALIANO</LanguageSelector>
            <ContactInfo primary Img={Calendar} text={t("company.times")} />
        </Wrapper>
    )
}

export default Footer
