import macchinario1 from "./macchinario1.webp"
import macchinario2 from "./macchinario2.webp"
import macchinario3 from "./macchinario3.webp"
import macchinario4 from "./macchinario4.webp"
import macchinario5 from "./macchinario5.webp"
import macchinario6 from "./macchinario6.webp"
import macchinario7 from "./macchinario7.webp"
import macchinario8 from "./macchinario8.webp"
import macchinario9 from "./macchinario9.webp"
import macchinario10 from "./macchinario10.webp"
import macchinario11 from "./macchinario11.webp"

const macchinari = [
    macchinario1,
    macchinario2,
    macchinario3,
    macchinario4,
    macchinario5,
    macchinario6,
    macchinario7,
    macchinario8,
    macchinario9,
    macchinario10,
    macchinario11,
]

export default macchinari
