import React from "react";
import { Home, About, Products, Contacts, Works, Policy } from "./pages";
import styled from "styled-components";
import { Route, Routes, useLocation } from "react-router-dom";
import { routes } from "./utils/routes";
import { useTranslation } from "react-i18next";
import { Footer } from "./components";
import { Navbar } from "@dodobardo/dodolib";

import { logo } from "./res/imgs";

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: ${(props) => (props.isContact ? "" : "100vh")};
`;

const App = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const routesToPass = t("navbar", { returnObjects: true }).map((el, index) => {
    return {
      path: routes[index],
      text: el,
    };
  });

  return (
    <>
      <Wrapper isContact={location.pathname === routes[5]}>
        <Navbar logo={logo} routes={routesToPass} />
      </Wrapper>
      <Routes>
        <Route path={routes[0]} exact element={<Home />} />
        <Route path={routes[1]} exact element={<About />} />
        <Route path={routes[2]} exact element={<Works />} />
        <Route path={routes[3]} exact element={<Products />} />
        <Route path={routes[4]} exact element={<Policy />} />
        <Route path={routes[5]} exact element={<Contacts />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
