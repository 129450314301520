import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { ThemeProvider } from "styled-components"
import theme from "./utils/theme"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import resources from "./res/locales"
import { BrowserRouter as Router } from "react-router-dom"

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: "it-IT",
        fallbackLng: "en-EN",
        defaultNS: "common",
        interpolation: {
            escapeValue: false,
        },
        resources,
    })

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Router>
                <App />
            </Router>
        </ThemeProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
