import React from "react";
import styled from "styled-components";
import { breakpointsMax } from "@dodobardo/dodolib";

const StyledMap = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 70vh;
  padding: 0px 100px 50px 100px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  @media ${breakpointsMax.tablet} {
    padding: 0px 0px;
  }
`;

const Map = () => {
  return (
    <Wrapper>
      <StyledMap
        loading="lazy"
        title="GMaps"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2872.0745099057826!2d11.126803!3d43.957824!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132af1bd590d8485%3A0x7d0646b3eb50d61f!2sOfficina%20Meccanica%20RODA%20S.R.L.!5e0!3m2!1sit!2sus!4v1713007535878!5m2!1sit!2sus"
      />
    </Wrapper>
  );
};

export default Map;
