const theme = {
    colors: {
        primary: "#102766",
        secondary: "#2355d9",
        tertiary: "#ddc0a0",
        grey: {
            primary: "#777777",
            secondary: "#f7f7f7",
            light: "#aeaeae",
        },
        white: "#f8f8f8",
        heroback: "rgba(171, 171, 171, 0.65)",
        footer: "#ababab",
    },
    fonts: {
        primary: ["Baskerville"],
        secondary: ["Broadway"],
    },
}

export default theme
