import React from "react"
import { Button } from "@dodobardo/dodolib"
import styled, { useTheme } from "styled-components"
import i18n from "i18next"

const Wrapper = styled.div`
    margin: 0px 10px;
`

const Container = styled.div`
    position: relative;
`
const Selector = styled.select`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
`

function LanguageSelector() {
    const theme = useTheme()
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
    }

    const getLanguage = (lng) => {
        switch (lng) {
            case "it-IT":
                return "ITALIANO"
            case "en-EN":
                return "ENGLISH"
            default:
                return "ENGLISH"
        }
    }

    return (
        <Wrapper>
            <Button
                borderColor={theme.colors.primary}
                textColor={theme.colors.primary}
                onClick={() => {}}
            >
                <Container>
                    {getLanguage(i18n.language)}
                    <Selector
                        onChange={(e) => changeLanguage(e.currentTarget.value)}
                        value={i18n.language}
                    >
                        <option value="it-IT">ITALIANO</option>
                        <option value="en-EN">ENGLISH</option>
                    </Selector>
                </Container>
            </Button>
        </Wrapper>
    )
}

export default LanguageSelector
