import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Col, Hero, Row, SectionTitle, breakpointsMax } from "@dodobardo/dodolib"
import { working_machine } from "../res/imgs"
import { work_1, work_2, work_3, work_4, work_5, work_6, work_7, work_8, work_9, work_10 } from "../res/imgs/lavorati"

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.white};
	padding: 50px;
	@media ${breakpointsMax.tablet} {
		padding: 40px;
	}
`

const ImageContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
`

const Image = styled.img`
	width: 250px;
	height: 300px;
`

const Works = () => {
	const { t } = useTranslation()

	return (
		<>
			<Hero title={t("heroes.titles.works")} subtitle={t("heroes.subtitles.works")} bgImage={working_machine} />
			<Container>
				<SectionTitle>{t("works.title")}</SectionTitle>
			</Container>
			<ImageContainer>
				<Col justify="center" align="center" style={{ padding: "50px", gap: "20px" }}>
					<Row style={{ gap: "10px" }} justify="center">
						<Image src={work_1} />
						<Image src={work_2} />
						<Image src={work_3} />
						<Image src={work_4} />
						<Image src={work_5} />
					</Row>
					<Row style={{ gap: "10px" }} justify="center">
						<Image src={work_6} />
						<Image src={work_7} />
						<Image src={work_8} />
						<Image src={work_9} />
						<Image src={work_10} />
					</Row>
				</Col>
			</ImageContainer>
		</>
	)
}

export default Works
