import React from "react"
import { Contacts as Wrapper } from "@dodobardo/dodolib"
import { useTranslation } from "react-i18next"
import { baseUrl } from "../utils/routes"

const Contacts = () => {
    const { t } = useTranslation()

    return <Wrapper t={t} host={baseUrl} />
}

export default Contacts
