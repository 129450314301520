import styled from "styled-components"

const Image = styled.img`
    -webkit-box-shadow: 12px 12px 23px 4px rgba(16, 39, 102, 0.86);
    box-shadow: ${({ left }) => (left ? "-12px" : "12px")} 12px 23px 4px
        rgba(16, 39, 102, 0.86);
    width: ${({ portrait }) => (portrait ? "320px" : "440px")};
    max-width: 100%;
`

export default Image
