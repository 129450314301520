import React from "react"
import styled from "styled-components"

const StyledHr = styled.div`
    height: 2px;
    margin: 0px auto;
    background-color: ${({ theme }) => theme.colors.primary};
`

const Wrapper = styled.div`
    padding: 50px 100px 25px 100px;
    background-color: ${({ theme }) => theme.colors.white};
`

const Hr = () => {
    return (
        <Wrapper>
            <StyledHr />
        </Wrapper>
    )
}

export default Hr
