import React from "react";
import {
  Section,
  breakpointsMax,
  SectionTitle,
  Hero,
} from "@dodobardo/dodolib";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { Image } from "../components";
import { hands_on } from "../res/imgs";
import {
  policy_1,
  policy_2,
  policy_3,
  policy_4,
  policy_5,
  policy_6,
  policy_7,
} from "../res/imgs/policy";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 50px;
  @media ${breakpointsMax.tablet} {
    padding: 40px;
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.grey.primary};
  }
`;

const Policy = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Hero
        title={t("heroes.titles.policy")}
        subtitle={t("heroes.subtitles.policy")}
        bgImage={hands_on}
      />
      <Container>
        <SectionTitle>{t("policy.title")}</SectionTitle>
      </Container>
      <Section
        bg={theme.colors.white}
        textColor={theme.colors.grey.primary}
        body={t("sections.policy.first")}
      >
        <Image src={policy_1} />
      </Section>
      <Section
        bg={theme.colors.white}
        textColor={theme.colors.grey.primary}
        body={t("sections.policy.second")}
        left
      >
        <Image src={policy_2} left />
      </Section>
      <Section
        bg={theme.colors.white}
        textColor={theme.colors.grey.primary}
        body={t("sections.policy.third")}
      >
        <Image src={policy_3} />
      </Section>
      <Section
        bg={theme.colors.white}
        textColor={theme.colors.grey.primary}
        body={t("sections.policy.fourth")}
        left
      >
        <Image src={policy_4} left />
      </Section>
      <Section
        bg={theme.colors.white}
        textColor={theme.colors.grey.primary}
        body={t("sections.policy.fifth")}
      >
        <Image src={policy_5} />
      </Section>
      <Section
        bg={theme.colors.white}
        textColor={theme.colors.grey.primary}
        body={t("sections.policy.sixth")}
        left
      >
        <Image src={policy_6} left portrait />
      </Section>
      <Section
        bg={theme.colors.white}
        textColor={theme.colors.grey.primary}
        body={t("sections.policy.seventh")}
      >
        <Image src={policy_7} />
      </Section>
    </>
  );
};

export default Policy;
