import React from "react"
import { Hero, ProductCard, Col as DefaultCol } from "@dodobardo/dodolib"
import { lathe } from "../res/imgs"
import foto_macchinari from "../res/imgs/macchinari"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Col = styled(DefaultCol)`
    gap: 70px;
    margin: 40px 0px;
`

const Products = () => {
    const { t } = useTranslation()
    const products = t("sections.products", { returnObjects: true })

    return (
        <>
            <Hero
                title={t("heroes.titles.products")}
                subtitle={t("heroes.subtitles.products")}
                bgImage={lathe}
            />
            <Col>
                {products.map((product, index) => {
                    if (index % 2 === 0) {
                        return (
                            <ProductCard
                                title={product.title}
                                body={product.body}
                            >
                                <img
                                    alt=""
                                    style={{ width: 300, height: "auto" }}
                                    src={foto_macchinari[index]}
                                />
                            </ProductCard>
                        )
                    }
                    return (
                        <ProductCard
                            title={product.title}
                            body={product.body}
                            left
                        >
                            <img
                                alt=""
                                style={{ width: 300, height: "auto" }}
                                src={foto_macchinari[index]}
                            />
                        </ProductCard>
                    )
                })}
            </Col>
        </>
    )
}

export default Products
