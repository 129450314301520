import React, { useEffect, useRef } from "react"
import {
    Section,
    breakpointsMax,
    SectionTitle,
    Row,
    Hero,
} from "@dodobardo/dodolib"
import styled, { useTheme } from "styled-components"
import { useTranslation } from "react-i18next"
import { Hr, Map, Image, Video } from "../components"
import { working_table, metal_piece_1, accredia, logo_cert } from "../res/imgs"
import video from "../res/video/minelli_video.mp4"
import ckd_video from "../res/video/ckd.mp4"

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 50px;
    @media ${breakpointsMax.tablet} {
        padding: 40px;
    }

    a {
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.grey.primary};
    }
`

const Home = () => {
    const { t } = useTranslation()
    const theme = useTheme()

    const videoRef = useRef(null)

    useEffect(() => {
        if (videoRef) {
            videoRef.current.defaultMuted = true
            videoRef.current.muted = true
        }
    }, [])

    return (
        <>
            <Hero
                title={t("heroes.titles.home")}
                subtitle={t("heroes.subtitles.home")}
                bgVideo={video}
            />
            <Container>
                <SectionTitle>{t("home.whoweare")}</SectionTitle>
            </Container>
            <Section
                bg={theme.colors.white}
                textColor={theme.colors.grey.primary}
                body={t("sections.home.first")}
            >
                <Image src={working_table} portrait />
            </Section>
            <Section
                bg={theme.colors.white}
                textColor={theme.colors.grey.primary}
                body={t("sections.home.second")}
                left
            >
                <Video
                    ref={videoRef}
                    left
                    portrait
                    autoPlay
                    muted
                    playsInline
                    loop
                >
                    <source src={ckd_video} type="video/mp4" />
                </Video>
            </Section>
            <Section
                bg={theme.colors.white}
                textColor={theme.colors.grey.primary}
                body={t("sections.home.third")}
            >
                <Image src={metal_piece_1} portrait />
            </Section>
            <Hr />
            <Container>
                <SectionTitle>{t("home.certifications")}</SectionTitle>
            </Container>
            <Section
                bg={theme.colors.white}
                textColor={theme.colors.grey.primary}
                body={t("sections.home.fourth")}
            >
                <Row align="center">
                    <img src={accredia} alt="accredia" />
                    <img src={logo_cert} alt="logo certificazione" />
                </Row>
            </Section>
            <Hr />
            <Container>
                <SectionTitle>{t("home.whereweare")}</SectionTitle>
            </Container>
            <Map />
        </>
    )
}

export default Home
