import common_en from "./en/common.json"
import common_it from "./it/common.json"

const languages = {
    "en-EN": {
        common: common_en,
    },
    "it-IT": {
        common: common_it,
    },
}

export default languages
